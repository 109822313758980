/**
 * Hides the passed banner and sets session storage if banner.storageKey is set
 * that way it's only displayed in current session if it hasn't been dismissed
 * @param {Object} banner The banner to be dismissed
 * @param {function} setBanner to set the banner state
 */
const dismissBanner = (banner, setBanner) => {
  if (banner.storageKey) sessionStorage.setItem(banner.storageKey, true);
  setBanner({ ...banner, display: false });
};

export default dismissBanner;
