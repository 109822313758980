import { useEffect, useState } from 'react';

import { useAuthContext } from 'src/components/AuthProvider';
import useMembership from 'src/api/useMembership';
import useProfile from 'src/api/useProfile';

const LEGACY_PRODUCT_TYPE = 'HDC 1.0';
const LEGACY_TEST_DRIVE_ASSET_NAME = 'Test Drive';

export const hasLegacyMembership = (profile) => {
  const assets = profile?.assets || [];
  return assets.some((a) => a.type === LEGACY_PRODUCT_TYPE && a.name !== LEGACY_TEST_DRIVE_ASSET_NAME);
};

/**
 * This hook will prevent legacy member visit my-account page, they will be redirect.
 */
export default function usePreventMemberMyAccount() {
  const { isAuthenticated, loading: authLoading } = useAuthContext();
  const { loading: membershipLoading, hasLegacyMembership } = useMembership();
  const { loading: profileLoading } = useProfile();
  const [skip, setSkip] = useState(false);

  const loading = authLoading || membershipLoading || profileLoading;

  useEffect(() => {
    // Not logged in, no effect
    if (loading || !isAuthenticated) return;
    if (skip) return;

    // User profile not loaded, no effect
    // eslint-disable-next-line no-debugger
    if (hasLegacyMembership) {
      window.location.replace(process.env.HDC_HAGERTY_ACCOUNT_PAGE);
    }
    // else, do nothing, continue showing current page
  }, [loading, isAuthenticated, hasLegacyMembership]);

  return setSkip;
}
