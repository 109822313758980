import React, { useState, useEffect } from 'react';
import { Link, PageProps, navigate } from 'gatsby';
import dateFormat from 'dateformat';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Helmet } from 'react-helmet';

import { isAgent } from 'src/api/client';
import { useAuthContext, useRequireAuthenticated } from 'src/components/AuthProvider';
import FullWidthLine from 'src/components/FullWidthLine';
import Banner, { BannerSettings } from 'src/components/molecule/Banner';
import PlanLevelWidget from 'src/components/PlanLevelWidget';
import { Translate } from 'src/components/Translate';
import useDateFormat from 'src/hooks/useDateFormat';
import usePaymentFailed from 'src/hooks/usePaymentFailed';
import usePreventMemberMyAccount from 'src/hooks/usePreventMemberMyAccount';
import { useShelbyCustomer } from 'src/hooks/useShelbyCustomer';
import useMembership from 'src/api/useMembership';
import useOrders from 'src/api/useOrders';
import useProfile from 'src/api/useProfile';
import Breadcrumbs from 'src/components/Breadcrumbs';
import LoadingContainer from 'src/components/LoadingContainer';
import StateFarmLogo from 'src/components/agents/StateFarmLogo';
import { isReady } from 'src/components/SplitContext/isReady';
import usePreviewCancelMembership from 'src/api/usePreviewCancelMembership';
import { useTracking, useTrackingContext } from 'src/components/TrackingContext';
import { usePreventAgentAccess } from 'src/hooks/usePreventAgentAccess';
import { Split, Treatment } from 'src/constants/Split';
import { Path } from 'src/constants/Route';
import { SimpleFooter } from 'src/features/footer';

const metadata = {
  title: 'Membership Account | Hagerty Drivers Club',
  description: 'Manage your Hagerty Drivers Club membership account.',
};

const BANNER_KEYS = {
  canceled: 'banner.canceled',
  expired: 'banner.expired',
  failedPayment: 'banner.failed.payment',
  willBeCanceled: 'banner.will.be.canceled',
  vip: 'banner.vip.setUpRenewalPlan',
} as const;

const MY_ACCOUNT_DATA = {
  welcome: 'myAccount.welcome',
  upgradeText: 'myAccount.upgrade.text',
  learnMore: 'myAccount.learnMore',
  benefitsTitle: 'myAccount.benefits.title',
  accountsTitle: 'myAccount.accounts.title',
  expiredBanner: 'myAccount.banner.expired.membership',
  failedPaymentBanner: 'myAccount.banner.expired.failed.payment',
  willBeCanceledBanner: 'myAccount.banner.membership.will.be.canceled',
  setUpRenewalPlanBanner: 'myAccount.banner.vip.setUpRenewalPlan',
  setUpRenewalPlan: 'myAccount.setUpRenewalPlan',
  cancelPlan: 'cancelPlan.banner.cancel',
  cancelPlanLater: 'cancelPlan.banner.cancel.later',
  cancelledPlan: 'cancelPlan.banner.cancelled',
} as const;

type BannerKeys = keyof typeof BANNER_KEYS;

function MyAccount({ location }: PageProps) {
  const { state = {} } = location;
  const { isAuthenticated, loading: userLoading } = useAuthContext();
  usePreventAgentAccess();
  useRequireAuthenticated();
  const { data: profile, ...profileQuery } = useProfile();
  const { data: membership, hasLegacyMembership, ...membershipQuery } = useMembership();
  const previewCancelMembership = usePreviewCancelMembership(membership?.data || {});
  const { orderData: orders } = useOrders();
  const [banner, setBanner] = useState<BannerSettings<typeof BANNER_KEYS[BannerKeys]>>(null);
  const [membershipCancelledNoRefund, setMembershipCancelledNoRefund] = useState<boolean>(false);
  const [callOnce, setCallOnce] = useState(false);
  const [loadingPreviewCancel, setLoadingPreviewCancel] = useState<boolean>(true);
  const { paymentFailed } = usePaymentFailed();
  const { isShelbyCustomer } = useShelbyCustomer();
  const splitIsReady = isReady();
  const treatments = useTreatments([Split.FeatureFreeTrial, Split.FeatureCancellationReasons]);
  const hasFreeTrial = treatments[Split.FeatureFreeTrial].treatment === Treatment.On && membership?.level === 5;
  const hasCancellationReasons = treatments[Split.FeatureCancellationReasons].treatment === Treatment.On;
  const { trackInteraction, trackView } = useTrackingContext();

  useTracking('my_account', null, true);

  const isPageLoading =
    userLoading ||
    profileQuery.loading ||
    membershipQuery.loading ||
    !isAuthenticated ||
    !profile ||
    !splitIsReady ||
    hasLegacyMembership;
  const format = useDateFormat();
  const expireAt = format(membership?.expireAt);
  const expireDate = expireAt !== 'Invalid Date' ? membership?.expireAt : new Date();
  const freeTrialExpireAt = dateFormat(expireDate, 'mmm d, yyyy');

  if (!isAgent) {
    usePreventMemberMyAccount();
  }

  useEffect(() => {
    if (isAuthenticated && membershipQuery?.startPolling) {
      membershipQuery?.startPolling(1000 * 60 * 5); // Poll interval - every 5 minutes
    }
  }, [isAuthenticated, membershipQuery]);

  useEffect(() => {
    if (membership?.data) {
      previewCancelMembership().then(({ data }) => {
        const details = data?.previewCancelMembership?.data;
        if (details?.refundType === 'none' && membership?.willBeCanceled()) {
          setMembershipCancelledNoRefund(true);
          setLoadingPreviewCancel(false);
        }
      });
    }
  }, [membership, splitIsReady]);

  const message = state?.['msg'];
  const cancelledWithMemberGraph = state?.['cancelledWithMemberGraph'];
  const hasMembership = membership?.hasMembership();

  useEffect(() => {
    if (message && membershipQuery?.stopPolling) {
      membershipQuery?.stopPolling();
      const date = state?.['date'];
      const storageKeyState = state?.['storageKey'];

      if (membership?.hasMembership() && message === 'changePlan.banner.notAllow') {
        setBanner({
          display: true,
          message,
          color: 'failure',
          ...(date ? { i18nValues: { date } } : {}),
        });
      } else if (
        membership?.hasMembership() ||
        message === MY_ACCOUNT_DATA.cancelPlan ||
        message === MY_ACCOUNT_DATA.cancelledPlan
      ) {
        setBanner({
          display: true,
          message,
          color: 'success',
          storageKey: storageKeyState,
          ...(date ? { i18nValues: { date } } : {}),
        });
      }

      if (!hasMembership && cancelledWithMemberGraph) {
        navigate('/my-account/', {
          state: {
            msg: MY_ACCOUNT_DATA.cancelledPlan,
            cancelledWithMemberGraph: true,
          },
        });
      }
    }
  }, [message, hasMembership, state]);

  useEffect(() => {
    if (!orders?.length || cancelledWithMemberGraph) {
      return;
    }

    if (membership?.wasCanceled() && membershipQuery?.stopPolling) {
      membershipQuery?.stopPolling();

      if (!sessionStorage.getItem(BANNER_KEYS.canceled)) {
        setBanner({
          display: true,
          message: MY_ACCOUNT_DATA.expiredBanner,
          color: 'warning',
          i18nValues: { date: format(new Date(membership?.data?.canceledAt)) },
          link: '/checkout/',
          storageKey: BANNER_KEYS.canceled,
        });
      }
    } else if (membership?.expiredLast30Days() && membershipQuery?.stopPolling) {
      membershipQuery?.stopPolling();

      if (paymentFailed && !sessionStorage.getItem(BANNER_KEYS.failedPayment)) {
        setBanner({
          display: true,
          message: MY_ACCOUNT_DATA.failedPaymentBanner,
          color: 'failure',
          i18nValues: { gear: membership.data.name },
          link: '/checkout/',
          storageKey: BANNER_KEYS.failedPayment,
        });
      } else if (!sessionStorage.getItem(BANNER_KEYS.expired)) {
        setBanner({
          display: true,
          message: MY_ACCOUNT_DATA.expiredBanner,
          color: 'warning',
          i18nValues: { date: expireAt },
          link: '/checkout/',
          storageKey: BANNER_KEYS.expired,
        });
      }
    }
  }, [membership?.data, orders, paymentFailed]);

  useEffect(() => {
    if (message) return; // Do not overwrite banners sent thru state.msg
    if (membership?.hasMembership() && !loadingPreviewCancel) {
      membershipQuery?.stopPolling();

      if (
        !membership.isVip() &&
        membership.willBeCanceled() &&
        !sessionStorage.getItem(BANNER_KEYS.willBeCanceled) &&
        !membershipCancelledNoRefund
      ) {
        setBanner({
          display: true,
          message: MY_ACCOUNT_DATA.willBeCanceledBanner,
          color: 'warning',
          i18nValues: { date: expireAt },
          link: '/my-account/change-plan/',
          storageKey: BANNER_KEYS.willBeCanceled,
        });
      } else if (!membership.isVip() && membershipCancelledNoRefund) {
        setBanner({
          display: true,
          message: MY_ACCOUNT_DATA.cancelPlanLater,
          i18nValues: { date: expireAt },
          color: 'warning',
          storageKey: BANNER_KEYS.willBeCanceled,
        });
      } else if (
        membership.isOneYearVip() &&
        membership.willBeCanceled() &&
        membership.expireIn30Days() &&
        !sessionStorage.getItem(BANNER_KEYS.vip)
      ) {
        setBanner({
          display: true,
          message: MY_ACCOUNT_DATA.setUpRenewalPlanBanner,
          color: 'warning',
          link: '/my-account/renew-plan/',
          storageKey: BANNER_KEYS.vip,
        });
      }
    }
  }, [membership?.data, message, membershipCancelledNoRefund, loadingPreviewCancel]);

  useEffect(() => {
    if (trackView && membership?.data && !callOnce) {
      if (!hasLegacyMembership && (membership.willBeCanceled() || membership.wasCanceled())) {
        trackView('hdc_myAccount_cancelled', null, {
          product_code: membership.data.name,
          commerce_product: [membership.data.name],
          hdc_status: 'inactive',
        });
      } else {
        trackView('my_account');
      }

      setCallOnce(true);
    }
  }, [trackView, membership, banner, isPageLoading]);

  const i18n = {
    breadcrumb: 'breadcrumbs.myAccount.text',
    benefitsBack: 'breadcrumbs.back',
    headline: 'profile.manageMembership.headline',
    renewalText: 'manageMembership.renewalText',
    freeTrialRenewalText: 'manageMembership.freeTrialRenewalText',
    cancelLink: 'choosePlan.cancelLink',
  };

  const cancelLink = hasCancellationReasons ? Path.MyAccountCancellation : Path.MyAccountCancelMembership;
  const cancelMembershipClick = () => {
    trackInteraction(
      'click',
      'cancel_hagerty_drivers_club',
      {
        product_code: membership.data.name,
        commerce_product: [membership.data.name],
      },
      'hdc_myAcccount_cancellation'
    );
  };

  const isAccountPage = document.referrer === process.env.HDC_HAGERTY_ACCOUNT_PAGE;

  const getOriginPath = () => {
    if (isAccountPage) {
      return process.env.HDC_HAGERTY_ACCOUNT_PAGE;
    }
    return Path.Benefits;
  };

  return (
    <div className="color-background-light-gray">
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
      </Helmet>
      {!isPageLoading && (
        <>
          {banner?.display && <Banner banner={banner} setBanner={setBanner} />}
          <div className="container container_center page__my-account my_account_container">
            <div className="status-info max-width_content">
              <Breadcrumbs originTransKey={i18n.benefitsBack} originPath={getOriginPath()} isExternal={isAccountPage} />

              <h2 className="text-display_2 benefit-header wrap-text">
                <Translate resourceKey={i18n.headline} />
              </h2>

              {profile && <PlanLevelWidget membership={membership} profile={profile} showDate />}
              {!membership?.hasMembership() && (
                <div className="text-lead_3" data-cy="non-member-upgrade-cta">
                  <div className="upgrade-text">
                    <Translate resourceKey={MY_ACCOUNT_DATA.upgradeText} />
                  </div>
                  <div className="learn-more">
                    <Link rel="nofollow" to="/benefits/" className="button button_secondary" data-cy="learn-more">
                      <Translate resourceKey={MY_ACCOUNT_DATA.learnMore} />
                    </Link>
                  </div>
                </div>
              )}
              {membership?.hasMembership() && !membership.willBeCanceled() && (
                <p>
                  {hasFreeTrial ? (
                    <Translate
                      resourceKey={i18n.freeTrialRenewalText}
                      values={{
                        date: freeTrialExpireAt,
                      }}
                      data-cy="free-trial-renewal-text"
                    />
                  ) : (
                    <Translate
                      resourceKey={i18n.renewalText}
                      values={{
                        date: expireAt,
                      }}
                      data-cy="renewal-text"
                    />
                  )}
                </p>
              )}
              <div className="inset-m"></div>
              <FullWidthLine />
              {membership?.hasMembership() && !membership.willBeCanceled() && (
                <>
                  <div className="inset-m" />
                  <Link
                    to={cancelLink}
                    state={{ nav: true }}
                    data-cy="cancel-membership"
                    className="button button_secondary"
                    onClick={cancelMembershipClick}
                  >
                    <Translate resourceKey={i18n.cancelLink} />
                  </Link>
                </>
              )}
            </div>
            <div className="inset-l" />
            <div className="inset-l" />
            <div className="inset-l" />

            {isShelbyCustomer && <StateFarmLogo className="position" />}
          </div>
        </>
      )}
      {isPageLoading && <LoadingContainer height={window.innerHeight > 440 ? window.innerHeight - 400 : 40} />}
      <div className="inset-l" />

      <SimpleFooter />
    </div>
  );
}

export default MyAccount;
