import { useMutation } from '@apollo/client';
import PreviewCancelMembershipMutation from './graphql/users/mutations/PreviewCancelMembershipMutation.graphql';

const usePreviewCancelMembership = (membership) => {
  const [previewCancelMembership] = useMutation(PreviewCancelMembershipMutation, {
    variables: {
      input: {
        data: {
          membershipId: membership.id,
          orderId: membership.orderId,
          type: 'hdcMembershipCancellation',
        },
      },
    },
  });

  return previewCancelMembership;
};

export default usePreviewCancelMembership;
