import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useOrders from 'src/api/useOrders';

export default function usePaymentFailed(paymentMethod) {
  const [paymentMethodError, setPaymentMethodError] = useState();
  const [paymentFailed, setPaymentFailed] = useState();
  const [lastOrder, setLastOrder] = useState({});

  const { orderData: createdOrders } = useOrders();

  useEffect(() => {
    if (paymentFailed) {
      const paymentError = lastOrder.payment.paymentIntent?.last_payment_error;
      if (paymentError && paymentMethod?.id === paymentError?.payment_method?.id) {
        setPaymentMethodError(paymentError.code);
      }
    }
  }, [paymentFailed, paymentMethod, lastOrder]);

  useEffect(() => {
    if (createdOrders?.length > 0) {
      const order = createdOrders[0];
      setLastOrder(order);
      setPaymentFailed(order.state === 'CREATED' && order.type === 'hdcMembershipRenewal');
    }
  }, [createdOrders]);

  return { paymentMethodError, paymentFailed, lastOrder };
}
usePaymentFailed.propTypes = {
  paymentMethod: PropTypes.object,
};
