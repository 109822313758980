import { useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';

import { useAuthContext } from 'src/components/AuthProvider';
import OrdersQuery from './graphql/users/queries/OrdersQuery.graphql';

const useOrders = (options?: QueryHookOptions, extraFilters?: Record<string, any>) => {
  const { isAuthenticated, loading: loadingUser } = useAuthContext();
  const searchInput = {
    ...(extraFilters && extraFilters),
    number: 1,
    limit: 10,
    offset: 0,
  };
  const queryOptions: QueryHookOptions = {
    variables: {
      searchInput,
    },
    skip: !isAuthenticated || loadingUser,
    errorPolicy: 'none', // Authenticated endpoint will raise an unhandled error
    ...options,
  };

  const {
    loading,
    error,
    fetchMore,
    data: { ordersResult: { data = [], meta = {} } = {} } = {},
    refetch,
    startPolling,
    stopPolling,
  } = useQuery(OrdersQuery, queryOptions);

  return {
    orderLoading: loading,
    orderError: error,
    orderData: data,
    orderMeta: meta,
    orderRefetch: refetch,
    orderFetchMore: fetchMore,
    orderStartPolling: startPolling,
    orderStopPolling: stopPolling,
  };
};

export default useOrders;
