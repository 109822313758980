import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { Translate } from 'src/components/Translate';
import dismissBanner from 'src/helpers/dismissBanner';
import { AlertsTip24 } from '@hagerty/icons';
import scrollToTop from 'src/helpers/scrollToTop';

export type BannerSettings<K> = {
  display: boolean;
  message: string;
  color: 'success' | 'warning' | 'failure' | 'unknown';
  i18nValues?: Record<string, any>;
  link?: string;
  storageKey?: K;
  rounded?: boolean;
  isNotDismissable?: boolean;
  timeout?: number;
  icon?: 'check-circle' | 'alerts-tip';
};

type BannerProps<K> = {
  banner: BannerSettings<K>;
  noTimeout?: boolean;
  setBanner: (settings: BannerSettings<K>) => void;
};

export default function Banner<K>({ banner, noTimeout = false, setBanner }: BannerProps<K>) {
  useEffect(() => {
    if (banner?.display === true) scrollToTop();
    let timeOut;
    if (banner?.display === true && !noTimeout) {
      timeOut = setTimeout(
        () => {
          setBanner({ ...banner, display: false });
        },
        banner?.timeout ? banner?.timeout : 30000
      );
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [banner]);

  const BannerIcon = () => {
    return (
      <>
        {((!banner.icon && banner.color !== 'unknown') || banner.icon === 'check-circle') && (
          <svg className="icon banner__icon" aria-hidden="true">
            <use
              href={`#16-check-circle${
                banner.color === 'warning' ? '-orange' : banner.color === 'failure' ? '-red' : ''
              }`}
            />
          </svg>
        )}
        {((!banner.icon && banner.color === 'unknown') || banner.icon === 'alerts-tip') && <AlertsTip24 />}
      </>
    );
  };

  return (
    <div className={`banner banner__${banner.color} ${banner.rounded && 'banner__rounded'}`} data-cy="banner">
      {banner.link ? (
        <>
          <BannerIcon />
          <Link to={banner.link} data-cy="banner-link">
            <span className="banner__message banner__link">
              <Translate resourceKey={banner.message} values={banner.i18nValues} useHtml />
            </span>
          </Link>
        </>
      ) : (
        <>
          <BannerIcon />
          <span className="banner__message">
            <Translate resourceKey={banner.message} values={banner.i18nValues} useHtml />
          </span>
        </>
      )}

      {!banner.isNotDismissable && (
        <svg onClick={() => dismissBanner(banner, setBanner)} className="icon banner__close" aria-hidden="true">
          <use href="#24-close-x" />
        </svg>
      )}
    </div>
  );
}
